import { ApiStatus } from "@Interfaces/enums";
import { ActionConsts } from "@Definitions";
import { IInteractionReducer, IAction } from "@Interfaces";

export const INITIAL_STATE: IInteractionReducer.InteractionState = {
    status: ApiStatus.init,
    interactions: {
        visitors: { Count: 0, Success: false, Enabled: false, Interactions: [], loading: true },
        winks: { Count: 0, Success: false, Enabled: false, Interactions: [], loading: true },
        sentWinks: { Count: 0, Success: false, Enabled: false, Interactions: [], loading: true },
        bans: { Count: 0, Success: false, Enabled: false, Interactions: [], loading: true },
        friends: { Count: 0, Success: false, Enabled: false, Interactions: [], loading: true },
        onlineFriends: {
            Count: 0,
            Success: false,
            Enabled: false,
            Interactions: [],
            loading: true,
        },
    },
    showMore: true,
};

export const INTERACTION_STATE = INITIAL_STATE;

export const InteractionReducer = (
    state = INITIAL_STATE,
    action: IAction<IInteractionReducer.InteractionState>
) => {
    switch (action.type) {
        case ActionConsts.Interaction.GetProfileVisitors:
        case ActionConsts.Interaction.GetProfileWinks:
        case ActionConsts.Interaction.GetProfileSentWinks:
        case ActionConsts.Interaction.DeleteWink:
        case ActionConsts.Interaction.GetBans:
        case ActionConsts.Interaction.GetFriends:
        case ActionConsts.Interaction.GetOnlineFriends:
        case ActionConsts.Interaction.SetIsFriendStatus:
        case ActionConsts.Interaction.UnBan:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.Interaction.Friend.Request:
            return {
                ...state,
                ...action.payload,
                status: ApiStatus.loading,
            };
        case ActionConsts.Interaction.Friend.Success:
            return {
                ...state,
                ...action.payload,
                status: ApiStatus.loaded,
            };
        case ActionConsts.Interaction.Friend.Failed:
            return {
                ...state,
                ...action.payload,
                status: ApiStatus.failed,
            };

        case ActionConsts.Interaction.SetLoading:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.Interaction.ResetReducer:
            return INITIAL_STATE;
        default:
            return state;
    }
};
