// #region Local Imports
import { IMetaDataReducer } from "@Reducers/metadata/MetaData";
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction } from "@Interfaces";
// #endregion Interface Imports

export const INITIAL_STATE: IMetaDataReducer.MetaDataState = {
    countries: [],
    cities: [],
    searchCities: [],
    towns: [],
    searchFilterQuestions: {
        Questions: [],
    },
    jobs: [],
};

export const METADATA_STATE = INITIAL_STATE;

export const MetaDataReducer = (state = INITIAL_STATE, action: IAction<any>) => {
    switch (action.type) {
        case ActionConsts.MetaData.GetSearchFilterQuestions:
        case ActionConsts.MetaData.FetchCountries:
        case ActionConsts.MetaData.FetchCities:
        case ActionConsts.MetaData.FetchTowns:
        case ActionConsts.MetaData.FetchJobs:
        case ActionConsts.MetaData.SetTowns:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.MetaData.ResetReducer:
            return INITIAL_STATE;
        default:
            return state;
    }
};
