// #region Global Imports
import { AnyAction } from "redux";
// #region Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IInboxPage } from "@Interfaces";
// #endregion Interface Imports

export const INITIAL_STATE: IInboxPage.IStateProps = {
    prioritized: undefined,
    requests: undefined,
    replied: undefined,
    chat: undefined,
    newRequests: undefined,
    loading: true,
};

export const INBOX_STATE = INITIAL_STATE;

export const InboxReducer = (state = INITIAL_STATE, action: AnyAction) => {
    switch (action.type) {
        case ActionConsts.Inbox.FetchReplied:
        case ActionConsts.Inbox.FetchChatPreviews:
        case ActionConsts.Inbox.FetchMessage:
        case ActionConsts.Inbox.SetLoading:
        case ActionConsts.Inbox.SetTraffic:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Inbox.FetchPrioritized:
            return {
                ...state,
                prioritized: action.payload,
                loading: false,
            };

        case ActionConsts.Inbox.FetchRequests:
            return {
                ...state,
                requests: action.payload,
                loading: false,
            };

        case ActionConsts.Inbox.FetchChat:
            return {
                ...state,
                chat: action.payload,
            };

        case ActionConsts.Inbox.PushNewRequests: {
            return {
                ...state,
                newRequests: action.payload,
            };
        }

        case ActionConsts.Inbox.SyncRequests: {
            return {
                ...state,
                newRequests: [],
                requests: action.payload,
            };
        }

        case ActionConsts.Inbox.ClearRequests: {
            return {
                ...state,
                newRequests: [],
            };
        }

        case ActionConsts.Inbox.FetchStickers: {
            return {
                ...state,
                stickers: action.payload,
            };
        }

        case ActionConsts.Inbox.SetMessageStatus: {
            return {
                ...state,
                sentMessageStatus: action.payload,
            };
        }

        case ActionConsts.Inbox.SetPrioritizedStatus: {
            return {
                ...state,
                chat: {
                    ...state.chat,
                    prioritizedState: action.payload,
                },
            };
        }

        case ActionConsts.Inbox.FetchInboxEmptyView: {
            return {
                ...state,
                emptyViewSearch: action.payload,
            };
        }

        case ActionConsts.Inbox.ResetReducer:
            return INITIAL_STATE;

        default:
            return state;
    }
};
