// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, IHomePage } from "@Interfaces";
// #endregion Interface Imports

export const INITIAL_STATE: IHomePage.IStateProps = {
    topComponent: "card",
    onlineUserCounts: {
        Success: false,
        Count: 0,
        MaleCount: 0,
        FemaleCount: 0,
    },
};

export const HOME_STATE = INITIAL_STATE;

type IMapPayload = IHomePage.Actions.IMapPayload;

export const HomeReducer = (state = INITIAL_STATE, action: IAction<IMapPayload>) => {
    switch (action.type) {
        case ActionConsts.Home.SetTopComponent:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.Home.SetReducer:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.Home.OnlineUserCount:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.Home.ResetReducer:
            return INITIAL_STATE;

        default:
            return state;
    }
};
