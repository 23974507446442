// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, ICommonReducer } from "@Interfaces";
// #endregion Interface Imports

const INITIAL_STATE: ICommonReducer.CommonState = {
    coin: 0,
    profileOccupancyRatio: "",
    notifications: {
        list: [],
        loading: true,
    },
    pusherChannel: undefined,
    buyStickerSuccess: false,
    showChatModal: false,
    scrollY: 0,
    isNewRegister: false,
    showPhotoUpload: false,
    showWinCreditModal: false,
    isUserGuest: false
};

export const COMMON_STATE = INITIAL_STATE;

export const CommonReducer = (state = INITIAL_STATE, { type, payload }: IAction<any>) => {
    switch (type) {
        case ActionConsts.Common.SaveScrollPosition:
            return {
                ...state,
                scrollY: payload,
            };

        case ActionConsts.Common.SetCoin:
            return {
                ...state,
                coin: payload,
            };

        case ActionConsts.Common.SetOccupancy:
            return {
                ...state,
                profileOccupancyRatio: payload,
            };

        case ActionConsts.Common.GetCoinNotification:
            return {
                ...state,
                notifications: {
                    list: payload,
                    loading: false,
                },
            };

        case ActionConsts.Common.SetNotificationsLoading:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loading: true,
                },
            };

        case ActionConsts.Common.GetChannel:
            return {
                ...state,
                pusherChannel: payload,
            };

        case ActionConsts.Common.SetStickerSuccess:
            return {
                ...state,
                buyStickerSuccess: payload,
            };

        case ActionConsts.Common.SetChatModal:
            return {
                ...state,
                showChatModal: payload,
            };

        case ActionConsts.Common.SetNewRegister:
            return {
                ...state,
                isNewRegister: payload,
            };
        case ActionConsts.Common.SetPhotoUploadModalState:
            return {
                ...state,
                showPhotoUpload: payload,
            };
        case ActionConsts.Common.SetNotification:
            return {
                ...state,
                notifications: {
                    list: [{ ...payload }, ...state.notifications.list],
                    loading: false,
                },
            };
        case ActionConsts.Common.SetWinCreditModal:
            return {
                ...state,
                showWinCreditModal: payload,
            };
        case ActionConsts.Common.SetGuestUser:
            return {
                ...state,
                isUserGuest: payload
            };
        case ActionConsts.Common.ResetReducer:
            return INITIAL_STATE;
        default:
            return state;
    }
};