// #region Local Imports
import { IInboxPage, IAction } from "@Interfaces";
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

export const INITIAL_STATE: IInboxPage.IFilterState = {
    filters: {
        isOnline: false,
        hasPhoto: false,
        unread: false,
        location: {} as IInboxPage.SelectedLocation,
        distance: 100,
        ageRange: {} as IInboxPage.SelectedAgeRange,
    },
};

export const INBOX_FILTER_STATE = INITIAL_STATE;

const toObject = (pairs: Iterable<any> | ArrayLike<any>) => {
    return Array.from(pairs).reduce(
        (acc, [key, value]) => Object.assign(acc, { [key]: value }),
        {}
    );
};

export const InboxFilterReducer = (
    state: IInboxPage.IFilterState = INITIAL_STATE,
    action: IAction<IInboxPage.IFilterState>
) => {
    switch (action.type) {
        case ActionConsts.InboxFilters.ResetReducer:
            return INITIAL_STATE;
        case ActionConsts.InboxFilters.SetFilters:
            return {
                ...state,
                filters: (toObject(
                    Object.entries({
                        ...state.filters,
                        ...action.payload!.filters,
                    }).filter(pair => pair[1] !== undefined)
                ) as unknown) as IInboxPage.Filters,
            };
        default:
            return state;
    }
};
