import { ApiStatus } from "@Interfaces/enums";
import { ActionConsts } from "@Definitions";
import { IInvoiceReducer, IAction } from "@Interfaces";

export const INITIAL_STATE: IInvoiceReducer.InvoiceState = {
    InvoiceList: [],
    addInvoiceInfo: {
        status: ApiStatus.init,
        error: undefined,
    },
};

export const INVOICE_STATE = INITIAL_STATE;

export const InvoiceReducer = (
    state = INITIAL_STATE,
    action: IAction<IInvoiceReducer.InvoiceState>
) => {
    switch (action.type) {
        case ActionConsts.Invoice.FetchUserInvoices:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.Invoice.AddInvoiceInfo.Request:
            return {
                ...state,
                addInvoiceInfo: {
                    status: ApiStatus.loading,
                    error: undefined,
                },
            };
        case ActionConsts.Invoice.AddInvoiceInfo.Success:
            return {
                ...state,
                addInvoiceInfo: {
                    status: ApiStatus.loaded,
                    error: undefined,
                },
            };
        case ActionConsts.Invoice.AddInvoiceInfo.Failed:
            return {
                ...state,
                addInvoiceInfo: {
                    error: "Error",
                    status: ApiStatus.failed,
                },
            };
        case ActionConsts.Invoice.ResetReducer:
            return INITIAL_STATE;
        default:
            return state;
    }
};
