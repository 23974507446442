// #region Interface Imports
import { ApiStatus } from "@Interfaces/enums";
import { IAction, IDashboard, PutMeUpHereModel } from "@Interfaces";
// #endregion Interface Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

export const INITIAL_STATE: IDashboard.IStateProps = {
    hotOrNot: {
        list: [],
        loading: true,
    },
    likeStatus: {
        reason: "",
        isMatch: false,
        success: undefined,
    },
    dislikeResponse: undefined,
    notificationCounts: {
        Success: true,
        NewConversationCount: 0,
        NewWinkCount: 0,
        NewRequestCount: 0,
        NewChatsCount: 0,
        NewPrioritizedCount: 0,
        NewProfileViewCount: 0,
        NewGiftCount: 0,
        NewLikeCount: 0,
    },
    likesToMe: { list: [], fetchedAll: false, currentPage: -1, loading: true },
    putMeUpHere: {
        isPurchaseRevealed: false,
    } as PutMeUpHereModel.State,
    recentPhotos: { photos: [], total: 0, page: 0, status: ApiStatus.init },
};

export const DASHBOARD_STATE = INITIAL_STATE;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DashboardReducer = (
    state = INITIAL_STATE,
    action: IAction<IDashboard.IStateProps>
) => {
    switch (action.type) {
        // TODO: Discard arbitrary reducers
        case ActionConsts.PutMeUpHere.SetPurchaseRevealed:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Dashboard.GetHotOrNotList:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Dashboard.HotOrNotListLoading:
            return {
                ...state,
                hotOrNot: {
                    ...state.hotOrNot,
                    loading: true,
                },
            };

        case ActionConsts.Notification.GetNotificationCounts:
            return {
                ...state,
                notificationCounts: {
                    ...state.notificationCounts,
                    ...action.payload,
                },
            };

        case ActionConsts.Dashboard.ReduceHotOrNotList:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Dashboard.Like:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Dashboard.Dislike:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Dashboard.FetchLikesToMe:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Dashboard.AddLikesToMe:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Dashboard.ResetLikesToMe:
            return {
                ...state,
                likesToMe: INITIAL_STATE.likesToMe,
            };

        case ActionConsts.Dashboard.SetLikesToMeLoading:
            return {
                ...state,
                likesToMe: {
                    ...state.likesToMe,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                    loading: action.payload?.likesToMe.loading!,
                },
            };

        case ActionConsts.Dashboard.SetVisibility:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.PutMeUpHere.GetPutMeUpHere:
            return {
                ...state,
                ...action.payload,
            };

        // #region // TODO: Refactor Reducers | Issue #694
        case ActionConsts.Dashboard.GetRecentPhotos.Loading:
            return {
                ...state,
                status: ApiStatus.loading,
            };

        case ActionConsts.Dashboard.GetRecentPhotos.Success:
            return {
                ...state,
                ...action.payload,
                status: ApiStatus.loaded,
            };

        case ActionConsts.Dashboard.GetRecentPhotos.Failure:
            return {
                ...state,
                ...action.payload,
                status: ApiStatus.failed,
            };
        // #endregion // TODO: Refactor Reducers | Issue #694

        case ActionConsts.Dashboard.ResetRecentPhotos:
            return {
                ...state,
                recentPhotos: INITIAL_STATE.recentPhotos,
            };

        case ActionConsts.Dashboard.ResetReducer:
            return INITIAL_STATE;

        default:
            return state;
    }
};
