// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, IAuthReducer } from "@Interfaces";
// #endregion Interface Imports

export const INITIAL_STATE: IAuthReducer.AuthState = {
    isAuthenticating: false,
    user: undefined,
    social: {
        facebook: {
            me: {
                birthday: "",
                email: "",
                gender: "",
                id: "",
                picture: "",
                thumbnail: "",
            },
            token: "",
        },
        apple: {},
    },
    changePassword: {
        Reason: "",
        Success: false,
        Token: "",
        UserId: "",
    },
    updateMail: {
        Reason: "",
        Success: false,
        Token: "",
        UserId: "",
    },
    resign: {
        Reason: "",
        Success: false,
    },
    captcha: {
        isRequired: false,
        token: "",
    },
};

export const AUTH_STATE = INITIAL_STATE;

export const AuthReducer = (state = INITIAL_STATE, action: IAction<any>) => {
    switch (action.type) {
        case ActionConsts.Account.Login:
        case ActionConsts.Account.AuthStateChange:
        case ActionConsts.Account.LoginFail:
        case ActionConsts.Account.LoginReset:
        case ActionConsts.Account.ChangePasswordSuccess:
        case ActionConsts.Account.ChangePasswordFail:
        case ActionConsts.Account.ChangePasswordReset:
        case ActionConsts.Account.UpdateEmailSuccess:
        case ActionConsts.Account.UpdateEmailReset:
        case ActionConsts.Account.UpdateEmailFail:
        case ActionConsts.Account.ResignSuccess:
        case ActionConsts.Account.ResignFail:
        case ActionConsts.Account.ResignReset:
        case ActionConsts.Account.Captcha:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Account.FacebookRegister:
            return {
                ...state,
                social: { facebook: { ...state.social.facebook, me: action.payload } },
            };

        case ActionConsts.Account.FacebookToken:
            return {
                ...state,
                social: { facebook: { ...state.social.facebook, token: action.payload } },
            };
        case ActionConsts.Account.AppleLogin.AppleRegister:
            return {
                ...state,
                social: { facebook: {}, apple: action.payload },
            };
        case ActionConsts.Account.Logout:
            return INITIAL_STATE;

        default:
            return state;
    }
};
