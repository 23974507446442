import { ActionConsts } from "@Definitions";
import { IProfileReducer, IAction } from "@Interfaces";

export const INITIAL_STATE: IProfileReducer.ProfileState = {
    self: undefined,
    occupancyQuestion: undefined,
    preferences: undefined,
    subscription: undefined,
    autoSubscription: undefined,
    editQuestion: undefined,
    profileEditStatus: undefined,
    uploadPhotoType: "edit",
    emptyQuestions: [],
    fetchedEmptyQuestions: false,
    currentEmptyQuestion: undefined,
    skippedQuestionIds: [],
};

export const PROFILE_STATE = INITIAL_STATE;

export const ProfileReducer = (
    state = INITIAL_STATE,
    action: IAction<IProfileReducer.ProfileState>
) => {
    switch (action.type) {
        case ActionConsts.Profile.GetProfile:
        case ActionConsts.Profile.GetProfileWithId:
        case ActionConsts.Profile.GetPreferences:
        case ActionConsts.Profile.SetAutoSubscription:
        case ActionConsts.Profile.GetSubscription:
        case ActionConsts.Profile.SetEmptyQuestions:
        case ActionConsts.Profile.SetEmptyQuestion:
        case ActionConsts.Profile.SetQuestion:
        case ActionConsts.Profile.SetUpdateStatus:
        case ActionConsts.Profile.SetEditQuestion:
        case ActionConsts.Profile.SetEditResult:
        case ActionConsts.Profile.SetMembershipType:
        case ActionConsts.Profile.GetPhotosFromPusher:
        case ActionConsts.Profile.SetUploadPhotoType:
        case ActionConsts.Profile.Set:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Profile.SetPreferences:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    ...action.payload,
                },
            };

        case ActionConsts.Profile.SeenProbation:
            return {
                ...state,
                self: state.self && {
                    ...state.self,
                    ...action.payload,
                },
            };

        case ActionConsts.Profile.ResetReducer:
            return INITIAL_STATE;

        default:
            return state;
    }
};
