// #region Global Imports
import { combineReducers } from "redux";
// #endregion Global Imports

// #region Local Imports
import { HomeReducer } from "./home";
import { AuthReducer } from "./auth";
import { ProfileReducer } from "./profile";
import { DashboardReducer } from "./dashboard";
import { CommonReducer } from "./common";
import { SearchReducer } from "./search";
import { SearchFilterReducer } from "./searchFilter";
import { MetaDataReducer } from "./metadata";
import { InteractionReducer } from "./interaction";
import { CampaignReducer } from "./campaign";
import { PaymentReducer } from "./payment";
import { InboxReducer } from "./inbox";
import { InboxFilterReducer } from "./inboxFilter";
import { InvoiceReducer } from "./invoice";
import { OfferReducer } from "./offer";
// #endregion Local Imports

export { HOME_STATE } from "./home";
export { AUTH_STATE } from "./auth";
export { PROFILE_STATE } from "./profile";
export { DASHBOARD_STATE } from "./dashboard";
export { COMMON_STATE } from "./common";
export { SEARCH_STATE } from "./search";
export { SEARCH_FILTER_STATE } from "./searchFilter";
export { INTERACTION_STATE } from "./interaction";
export { METADATA_STATE } from "./metadata";
export { CAMPAIGN_STATE } from "./campaign";
export { PAYMENT_STATE } from "./payment";
export { INBOX_STATE } from "./inbox";
export { INBOX_FILTER_STATE } from "./inboxFilter";
export { INVOICE_STATE } from "./invoice";
export { OFFER_STATE } from "./offer";

export default combineReducers({
    dashboard: DashboardReducer,
    home: HomeReducer,
    auth: AuthReducer,
    profile: ProfileReducer,
    common: CommonReducer,
    search: SearchReducer,
    searchFilter: SearchFilterReducer,
    metadata: MetaDataReducer,
    interaction: InteractionReducer,
    campaign: CampaignReducer,
    payment: PaymentReducer,
    inbox: InboxReducer,
    inboxFilter: InboxFilterReducer,
    invoice: InvoiceReducer,
    offer: OfferReducer,
});
