import { ApiStatus } from "@Interfaces/enums";
import { ActionConsts } from "@Definitions";
import { ICampaignReducer, CampaignModel, IAction } from "@Interfaces";

export const INITIAL_STATE: ICampaignReducer.CampaignState = {
    status: ApiStatus.init,
    data: [],
    error: undefined,
};

export const CAMPAIGN_STATE = INITIAL_STATE;

export const CampaignReducer = (
    state = INITIAL_STATE,
    action: IAction<CampaignModel.Campaign[]>
) => {
    switch (action.type) {
        case ActionConsts.Campaign.Active.Request:
            return {
                ...state,
                status: ApiStatus.loading,
                error: undefined,
            };
        case ActionConsts.Campaign.Active.Success:
            return {
                ...state,
                data: action.payload,
                status: ApiStatus.loaded,
                error: undefined,
            };
        case ActionConsts.Campaign.Active.Failed:
            return {
                ...state,
                error: "Error",
                status: ApiStatus.failed,
            };
        case ActionConsts.Campaign.ResetReducer:
            return INITIAL_STATE;
        default:
            return state;
    }
};
