// #region Local Imports
import { ISearchPage, IAction } from "@Interfaces";
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

export const INITIAL_STATE: ISearchPage.IFilterState = {
    filters: {
        isOnline: false,
        hasPhoto: false,
        isNewUser: false,
        location: {} as ISearchPage.SelectedLocation,
        distance: 100,
        ageRange: {} as ISearchPage.SelectedAgeRange,
        gender: 0,
    },
};

export const SEARCH_FILTER_STATE = INITIAL_STATE;

const toObject = (pairs: Iterable<any> | ArrayLike<any>) => {
    return Array.from(pairs).reduce(
        (acc, [key, value]) => Object.assign(acc, { [key]: value }),
        {}
    );
};

export const SearchFilterReducer = (
    state: ISearchPage.IFilterState = INITIAL_STATE,
    action: IAction<ISearchPage.IFilterState>
) => {
    switch (action.type) {
        case ActionConsts.SearchFilters.ResetReducer:
            if (action?.payload?.onlyAdvanced ?? false) {
                return {
                    ...state,
                    filters: (toObject(
                        Object.entries(state.filters).filter(pair =>
                            Object.prototype.hasOwnProperty.call(INITIAL_STATE, pair[0])
                        )
                    ) as unknown) as ISearchPage.Filters,
                };
            }
            return INITIAL_STATE;
        case ActionConsts.SearchFilters.SetFilters:
            return {
                ...state,
                filters: (toObject(
                    Object.entries({
                        ...state.filters,
                        ...action.payload!.filters,
                    }).filter(pair => pair[1] !== undefined)
                ) as unknown) as ISearchPage.Filters,
            };
        default:
            return state;
    }
};
