import { ApiStatus } from "@Interfaces/enums";
import { ActionConsts } from "@Definitions";
import { IOfferReducer, IAction } from "@Interfaces";

export const INITIAL_STATE: IOfferReducer.OfferState = {
    status: ApiStatus.init,
    data: undefined,
    error: undefined,
};

export const OFFER_STATE = INITIAL_STATE;

export const OfferReducer = (state = INITIAL_STATE, action: IAction<IOfferReducer.OfferState>) => {
    switch (action.type) {
        case ActionConsts.Offer.Request:
            return {
                ...state,
                ...action.payload,
                status: ApiStatus.loading,
            };
        case ActionConsts.Offer.Success:
            return {
                ...state,
                ...action.payload,
                status: ApiStatus.loaded,
            };
        case ActionConsts.Offer.Failed:
            return {
                ...state,
                ...action.payload,
                status: ApiStatus.failed,
            };
        case ActionConsts.Offer.ResetReducer:
            return INITIAL_STATE;
        default:
            return state;
    }
};
