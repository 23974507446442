import { ApiStatus } from "@Interfaces/enums";
import { ActionConsts } from "@Definitions";
import { IPaymentReducer, IAction } from "@Interfaces";

export const INITIAL_STATE: IPaymentReducer.PaymentState = {
    selectedCampaign: undefined,
    selectedOperator: undefined,
    subscriptions: {
        status: ApiStatus.init,
    },
    installment: {
        status: ApiStatus.init,
    },
    mobilePaymentCheck: {
        status: ApiStatus.init,
        data: undefined,
    },
};

export const PAYMENT_STATE = INITIAL_STATE;

export const PaymentReducer = (
    state = INITIAL_STATE,
    action: IAction<IPaymentReducer.PaymentState>
) => {
    switch (action.type) {
        case ActionConsts.Payment.SelectedCampaign:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.Payment.SelectedOperator:
            return {
                ...state,
                ...action.payload,
            };
        case ActionConsts.Payment.Subscriptions.Request:
            return {
                ...state,
                ...action.payload,
                subscriptions: {
                    status: ApiStatus.loading,
                    error: undefined,
                },
            };
        case ActionConsts.Payment.Subscriptions.Success:
            return {
                ...state,
                ...action.payload,
                subscriptions: {
                    data: action.payload?.subscriptions.data,
                    status: ApiStatus.loaded,
                    error: undefined,
                },
            };
        case ActionConsts.Payment.Subscriptions.Failed:
            return {
                ...state,
                ...action.payload,
                subscriptions: {
                    status: ApiStatus.failed,
                    error: action.payload?.subscriptions.error,
                },
            };
        case ActionConsts.Payment.Subscriptions.Reset:
            return {
                ...state,
                ...action.payload,
                subscriptions: INITIAL_STATE.subscriptions,
            };
        case ActionConsts.Payment.Installment.Request:
            return {
                ...state,
                ...action.payload,
                installment: {
                    status: ApiStatus.loading,
                    error: undefined,
                },
            };
        case ActionConsts.Payment.Installment.Success:
            return {
                ...state,
                ...action.payload,
                installment: {
                    data: action.payload?.installment.data,
                    status: ApiStatus.loaded,
                    error: undefined,
                },
            };
        case ActionConsts.Payment.Installment.Failed:
            return {
                ...state,
                ...action.payload,
                installment: {
                    status: ApiStatus.failed,
                    error: action.payload?.installment.error,
                },
            };
        case ActionConsts.Payment.Installment.Reset:
            return {
                ...state,
                ...action.payload,
                installment: INITIAL_STATE.installment,
            };
        case ActionConsts.Payment.MobilePaymentCheck.Request:
            return {
                ...state,
                mobilePaymentCheck: {
                    status: ApiStatus.loading,
                    error: undefined,
                },
            };
        case ActionConsts.Payment.MobilePaymentCheck.Success:
            return {
                ...state,
                ...action.payload,
                mobilePaymentCheck: {
                    data: action.payload?.mobilePaymentCheck.data,
                    status: ApiStatus.loaded,
                    error: undefined,
                },
            };
        case ActionConsts.Payment.MobilePaymentCheck.Failed:
            return {
                ...state,
                ...action.payload,
                mobilePaymentCheck: {
                    status: ApiStatus.failed,
                    error: action.payload?.mobilePaymentCheck.error,
                },
            };
        case ActionConsts.Payment.MobilePaymentCheck.Reset:
            return {
                ...state,
                ...action.payload,
                mobilePaymentCheck: INITIAL_STATE.mobilePaymentCheck,
            };
        case ActionConsts.Payment.ResetReducer:
            return INITIAL_STATE;
        default:
            return state;
    }
};
