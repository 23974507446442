// #region Local Imports
import { IAction, ISearchPage } from "@Interfaces";
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

export const INITIAL_STATE: ISearchPage.IState = {
    loading: true,
    searchResults: [],
    currentPage: 0,
    total: 0,
};

export const SEARCH_STATE = INITIAL_STATE;

export const SearchReducer = (
    state: ISearchPage.IState = INITIAL_STATE,
    action: IAction<ISearchPage.IState>
) => {
    switch (action.type) {
        case ActionConsts.Search.SetSearchResults:
        case ActionConsts.Search.SetLoading:
            return {
                ...state,
                ...action.payload,
            };

        case ActionConsts.Search.AddSearchResults:
            return {
                ...state,
                currentPage: action.payload!.currentPage,
                total: action.payload!.total,
                searchResults: [...state.searchResults, ...action.payload!.searchResults],
                loading: false,
            };

        case ActionConsts.Search.ResetReducer:
            return INITIAL_STATE;

        default:
            return state;
    }
};
