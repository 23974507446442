// #region Global Imports
import * as React from "react";
import App from "next/app";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import withRedux from "next-redux-wrapper";
import Nookies from "nookies";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import relativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import Head from "next/head";
import { UAParser } from "ua-parser-js";
import "react-credit-cards/es/styles-compiled.css";
import * as Sentry from "@sentry/browser";
import { persistStore } from "redux-persist";
// #endregion Global Imports

// #region Local Imports
import { appWithTranslation, withTranslation } from "@Server/i18n";
import { pubSub } from "@Definitions/PubSub";
import {
    ActionConsts,
    Redirect,
    FetchDashboardData,
    theme,
    DeviceContext,
    attachRouterListener,
    RedirectIfNotAuthorized,
} from "@Definitions";
import { AppWithStore, AppContextWithStore, IAppState } from "@Interfaces";
import {
    PusherComponent,
    PutMeUpHere,
    Header,
    Modal,
    RegisterBox,
    IntervalComponent,
    NoSSR,
} from "@Components";
import { Analytics, FacebookService, ProfileService } from "@Services";
import { makeStore } from "@Redux";

import "@Static/css/main.scss";
// #endregion Local Imports

Sentry.init({ dsn: "https://33a0e0ed64264b6fa896c88d295eb00c@o245899.ingest.sentry.io/5198322" });
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

class WebApp extends App<AppWithStore> {
    static async getInitialProps({ Component, ctx }: AppContextWithStore) {
        const { account } = Nookies.get(ctx);

        if (account) {
            const parsedAccount = JSON.parse(account);

            ctx.store?.dispatch?.({
                payload: parsedAccount,
                type: ActionConsts.Account.Login,
            });

            if (ctx.pathname === "/") {
                Redirect(ctx, "dashboard", "replace");
            }
        }

        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

        const deviceType =
            ctx.req !== undefined
                ? new UAParser(ctx.req.headers["user-agent"]).getDevice().type
                : "";

        return { pageProps, deviceType, account };
    }

    state: IAppState = {
        registerModalIsOpen: false,
        openRegisterModalUnsubscription: undefined,
        accountCancellationUnsubscribe: undefined,
    };

    async componentDidMount() {
        // init analytics service
        Analytics.init();

        const [dispatch, cookie] = [this.props.store?.dispatch, Nookies.get()];

        dayjs.locale("tr");

        if (dispatch) {
            attachRouterListener(dispatch);

            if (cookie.account) {
                FetchDashboardData(dispatch);

                const canSendStartedSessionResponse = await ProfileService.CanSendStartedSession();
                if (canSendStartedSessionResponse.CanSend) {
                    Analytics.event("Started Session");
                }
            }
        }

        FacebookService.init();

        const openRegisterModalSubscription = pubSub.subscribe("openRegisterModal", () => {
            this.setState({ registerModalIsOpen: true });
        });

        const accountCancellationSubscribe = pubSub.subscribe("accountCancellation", () => {
            RedirectIfNotAuthorized();
        });

        this.setState(state => ({
            ...state,
            openRegisterModalUnsubscription: openRegisterModalSubscription.unsubscribe,
            accountCancellationUnsubscribe: accountCancellationSubscribe.unsubscribe,
        }));
    }

    componentWillUnmount() {
        if (this.state.openRegisterModalUnsubscription)
            this.state.openRegisterModalUnsubscription();
        if (this.state.accountCancellationUnsubscribe) this.state.accountCancellationUnsubscribe();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo.componentStack);
            });

            Sentry.captureException(error);
        });

        super.componentDidCatch(error, errorInfo);
    }

    render() {
        const { Component, pageProps, store, deviceType, account, t, router } = this.props;
        // const persistor = persistStore(store);
        persistStore(store);
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <DeviceContext.Provider value={deviceType}>
                        <Head>
                            <title>
                                Siberalem.com | Türkiye&rsquo;nin En Büyük Arkadaşlık Sitesi
                            </title>
                            <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                            />

                            <meta
                                name="keywords"
                                content="siberalem, arkadaşlık, arkadaş, sevgili, evlilik, sohbet, aşk, arkadaşlık sitesi, bayan arkadaş, kız arkadaş, sohbet, erkek arkadaş, bay arkadaş, sohpet, dost"
                            />
                            <meta
                                name="description"
                                content="Siberalem.com: Türkiye'nin en büyük ve en işe yarayan arkadaşlık sitesi. Aradığınız arkadaşı bulmak için hemen Siberalem'e ücretsiz üye olun."
                            />
                            <link
                                rel="canonical"
                                href={`https://www.siberalem.com/${router.asPath}`}
                            />
                        </Head>

                        {account && <Header t={t} />}

                        {account && (
                            <div className="my-5">
                                <PutMeUpHere t={t} />
                            </div>
                        )}

                        <NoSSR>
                            {account && <PusherComponent />}
                            {account && <IntervalComponent />}
                        </NoSSR>

                        <Component {...pageProps} />
                        {this.state.registerModalIsOpen && (
                            <Modal>
                                <RegisterBox
                                    t={t}
                                    onCloseClick={() =>
                                        this.setState({ registerModalIsOpen: false })
                                    }
                                />
                            </Modal>
                        )}
                    </DeviceContext.Provider>
                </ThemeProvider>
            </Provider>
        );
    }
}

export default withRedux(makeStore)(
    appWithTranslation(withTranslation(["common", "dashboard"])(WebApp as any))
);
